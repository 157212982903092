import { css, down, styled, thd } from '@smooth-ui/core-sc';
import { transparentize } from 'polished';

export default styled.button`
  display: none;
  cursor: pointer;

  ${down(
    'lg',
    css`
      background-color: ${props =>
        props.openMobileMenu ? transparentize(0.25, '#151d29') : 'transparent'};
      border: none;
      display: inline-block;
      font-size: 0.75rem;
      height: 54px;
      position: absolute;
      right: ${props => (props.openMobileMenu ? '250px' : '10px')};
      text-transform: uppercase;
      top: 11px;
      transition: all 0.3s ease 0s;
      width: 54px;
      z-index: ${props => (props.openMobileMenu ? '1' : '0')};
    `
  )};

  & > span {
    background-color: ${props =>
      props.openMobileMenu ? '' : thd('customMenuColor', '#fff')};
    display: block;
    height: 4px;
    left: 7px;
    position: absolute;
    right: 7px;
    top: 15px;

    &::after,
    &::before {
      background-color: ${thd('customMenuColor', '#fff')};
      content: '';
      display: block;
      height: 4px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &::before {
      top: -8px;
      ${props =>
        props.openMobileMenu ? 'top: 0; transform: rotate(45deg)' : ''};
    }
    &::after {
      bottom: -8px;
      ${props =>
        props.openMobileMenu ? 'bottom: 0; transform: rotate(-45deg)' : ''};
    }
  }

  & > div {
    color: ${thd('customMenuColor', '#fff')};
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
