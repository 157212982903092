import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NavContainer from './HeaderNavContainer';
import MenuToggleButton from './HeaderNavMenuToggleButton';
import TopLevel from './HeaderNavTopLevel';
import SubLevel from './HeaderNavSubLevel';
import HeaderNavLink from './HeaderNavLink';
import PrivateComponent from '_platform/src/utils/PrivateComponent';
import { styled, withTheme } from '@smooth-ui/core-sc';

const Overlay = styled.div`
  background-color: ${p =>
    p.theme.customMobileMenuOverlayBackground || 'rgba(0, 0, 0, 0.5)'};
  bottom: 0;
  height: 100vh;
  left: 0;
  opacity: ${p => (p.isMobileMenuOpen ? '1' : '0')};
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease 0s;
  visibility: ${p => (p.isMobileMenuOpen ? 'visible' : 'hidden')};
  z-index: 1;
`;

const SubMenu = ({ depth, parent, onCloseMobileMenu }) => (
  <SubLevel depth={depth}>
    {parent.children.map(
      item =>
        item.inMenu &&
        !!item.menuPath && (
          <li key={item.menuId}>
            <HeaderNavLink
              to={item.menuPath}
              activeClassName="menu-active"
              depth={depth}
              onClick={onCloseMobileMenu}
            >
              {item.itemName}
            </HeaderNavLink>
            {item.children && item.children.length > 0 && (
              <SubMenu
                parent={item}
                depth={depth + 1}
                onCloseMobileMenu={onCloseMobileMenu}
              />
            )}
          </li>
        )
    )}
  </SubLevel>
);

SubMenu.propTypes = {
  depth: PropTypes.number, // eslint-disable-line react/require-default-props
  onCloseMobileMenu: PropTypes.func.isRequired,
  parent: PropTypes.object.isRequired,
};

class HeaderNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileMenuOpen: false,
    };
  }

  onMobileMenuToggle = () => {
    this.setState(prevState => ({
      isMobileMenuOpen: !prevState.isMobileMenuOpen,
    }));
    document.body.style.overflow = this.state.isMobileMenuOpen
      ? 'visible'
      : 'hidden';
  };

  render() {
    if (!this.props.menu || this.props.menu.length < 1) {
      return null;
    }

    return (
      <React.Fragment>
        {/* Keyboard event is handled on the button (enter/space/escape) */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <Overlay
          isMobileMenuOpen={this.state.isMobileMenuOpen}
          onClick={() => {
            this.setState({ isMobileMenuOpen: false });
            document.body.style.overflow = 'visible';
          }}
        />
        <MenuToggleButton
          onClick={this.onMobileMenuToggle}
          openMobileMenu={this.state.isMobileMenuOpen}
        >
          <span />
          <div>{this.state.isMobileMenuOpen ? 'Close' : 'Menu'}</div>
        </MenuToggleButton>
        <NavContainer openMobileMenu={this.state.isMobileMenuOpen}>
          <TopLevel>
            {this.props.showLoginInMenu && (
              <li>
                <HeaderNavLink
                  to={this.props.theme.settingsApp.loginPagePath}
                  activeClassName="menu-active"
                  onClick={() => {
                    this.state.isMobileMenuOpen &&
                      this.setState({ isMobileMenuOpen: false });
                    document.body.style.overflow = 'visible';
                  }}
                >
                  Log In
                </HeaderNavLink>
              </li>
            )}
            {this.props.menu &&
              this.props.menu.map(
                item =>
                  item.inMenu &&
                  !!item.menuPath && (
                    <li key={item.menuId}>
                      <HeaderNavLink
                        to={item.menuPath}
                        activeClassName="menu-active"
                        exact={item.menuPath === '/'}
                        onClick={() => {
                          this.state.isMobileMenuOpen &&
                            this.setState({ isMobileMenuOpen: false });
                          document.body.style.overflow = 'visible';
                        }}
                      >
                        {item.itemName}
                      </HeaderNavLink>
                      {item.children && item.children.length > 0 && (
                        <SubMenu
                          parent={item}
                          depth={2}
                          onCloseMobileMenu={() => {
                            this.state.isMobileMenuOpen &&
                              this.setState({ isMobileMenuOpen: false });
                            document.body.style.overflow = 'visible';
                          }}
                        />
                      )}
                    </li>
                  )
              )}
            {this.props.showLogoutInMenu && (
              <PrivateComponent>
                <li>
                  <HeaderNavLink
                    to={this.props.theme.settingsApp.logoutPagePath}
                    activeClassName="menu-active"
                    onClick={() => {
                      this.state.isMobileMenuOpen &&
                        this.setState({ isMobileMenuOpen: false });
                      document.body.style.overflow = 'visible';
                    }}
                  >
                    Logout
                  </HeaderNavLink>
                </li>
              </PrivateComponent>
            )}
          </TopLevel>
        </NavContainer>
      </React.Fragment>
    );
  }
}

HeaderNav.propTypes = {
  menu: PropTypes.array,
  showLoginInMenu: PropTypes.bool,
  showLogoutInMenu: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

HeaderNav.defaultProps = {
  menu: undefined,
  showLoginInMenu: undefined,
  showLogoutInMenu: undefined,
};

export default withTheme(HeaderNav);
